<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        At an altitude of
        <latex-number :number="altitude" unit="\text{km}" />
        above the Earth's surface, the temperature is approximately
        <latex-number :number="temp" unit="^\circ\text{C,}" />
        and the pressure of the atmosphere is about
        <latex-number :number="pressure" unit="\text{Torr.}" />
      </p>

      <p class="mb-3">
        a) What is the root mean squared speed,
        <stemble-latex content="$\text{u}_\text{rms},$" />
        of
        <chemical-latex content="O2" /> at this altitude?
      </p>

      <calculation-input
        v-model="inputs.rmsSpeed"
        class="mb-6"
        prepend-text="$\text{u}_\text{rms}:$"
        append-text="$\text{m/s}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Under these conditions, will the average kinetic energy of ozone
        <chemical-latex content="O3," />
        be greater than, less than, or equal to the average kinetic energy of oxygen gas
        <chemical-latex content="O2?" />
        Explain why in one to two sentences.
      </p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explanation" outlined></s-textarea>
      </p>
    </v-form>
    <ai-loading-overlay v-if="isSubmitting" />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import STextarea from '@/common/components/STextarea.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'Question453',
  components: {
    AiLoadingOverlay,
    STextarea,
    LatexNumber,
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        rmsSpeed: null,
        explanation: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content.value;
    },
    altitudeData() {
      if (this.versionNumber === 1) {
        return {
          altitude: '40',
          temp: '-23',
          pressure: '2.15',
        };
      } else if (this.versionNumber === 2) {
        return {
          altitude: '50',
          temp: '-3',
          pressure: '0.598',
        };
      } else if (this.versionNumber === 3) {
        return {
          altitude: '60',
          temp: '-26',
          pressure: '0.165',
        };
      } else if (this.versionNumber === 4) {
        return {
          altitude: '70',
          temp: '-54',
          pressure: '0.0390',
        };
      } else if (this.versionNumber === 5) {
        return {
          altitude: '80',
          temp: '-75',
          pressure: '0.00825',
        };
      } else {
        return {
          altitude: 'Error',
          temp: 'ERROR',
          pressure: 'ERROR',
        };
      }
    },
    altitude() {
      return this.altitudeData.altitude;
    },
    temp() {
      return this.altitudeData.temp;
    },
    pressure() {
      return this.altitudeData.pressure;
    },
  },
};
</script>
